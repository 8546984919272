import React, { Component } from 'react';
import { Link } from 'gatsby';
import styled from 'react-emotion';
import { css } from 'emotion';

import { colors, layout } from '../../shared/theme';
import Logo from '../../assets/images/logo.svg';
import LogoIcon from '../../assets/images/logo-icon.svg';

import Container from '../Container';
import Button from '../Button';

const NavigationContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  padding-top: 50px;
  padding-bottom: 50px;
  align-items: center;
  & > button {
    background: none;
    border: none;
    width: 36px;
    height: 36px;
    padding: 6px;
    cursor: pointer;
    rect {
      transition: transform 0.2s, opacity 0.2s;
      transform-origin: 12px 9px;
    }
    &.active {
      rect:nth-of-type(1) {
        transform: rotate(-45deg) translateY(8px);
      }
      rect:nth-of-type(2) {
        opacity: 0;
      }
      rect:nth-of-type(3) {
        transform: rotate(45deg) translateY(-8px);
      }
    }
    ${layout.bp.large} {
      display: none;
    }
    svg {
      display: block;
      width: 24px;
    }
  }
`;

const Nav = styled('nav')`
  position: fixed;
  background-color: ${colors.mid};
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  z-index: -1;
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  display: ${props => (props.isVisible ? 'flex' : 'none')};
  ${layout.bp.large} {
    padding-top: 0;
    z-index: 0;
    display: block;
    height: auto;
    background-color: transparent;
    position: relative;
  }
`;
const navItem = css`
  display: block;
  margin: 0.5em;
  ${layout.bp.large} {
    margin: 0;
    margin-left: 1rem;
    display: inline-flex;
  }
`;

const navLink = css`
  text-decoration: none;
  font-weight: 600;
  font-size: 16px;
  color: ${colors.dark};
  ${navItem};
  &:hover {
    color: ${colors.primary};
  }
`;

class Navigation extends Component {
  state = {
    isNavActive: false,
  };

  toggleNav = () => {
    this.setState(state => ({
      isNavActive: !state.isNavActive,
    }));
  };

  _closeNav = () => {
    this.setState({ isNavActive: false });
  };

  render() {
    const { pathname } = this.props.location;
    return (
      <div
        css={css`
          position: ${this.state.isNavActive ? 'fixed' : 'absolute'};
          top: 0;
          left: 0;
          width: 100%;
          z-index: 1000;
        `}
      >
        <NavigationContainer>
          <Link
            to="/"
            css={css`
              display: block;
            `}
          >
            <h1
              css={css`
                margin: 0;
              `}
            >
              <LogoIcon
                css={css`
                  height: 35px;
                  position: absolute;
                  left: 50%;
                  top: 3rem;
                  transform: translateX(-50%);
                  display: ${this.state.isNavActive ? 'block' : 'none'};
                  ${layout.bp.large} {
                    display: none;
                  }
                `}
              />
              <Logo
                css={css`
                  height: 35px;
                  display: ${this.state.isNavActive ? 'none' : 'block'};
                  ${layout.bp.large} {
                    display: block;
                  }
                `}
              />
            </h1>
          </Link>
          <button
            className={this.state.isNavActive ? 'active' : ''}
            onClick={this.toggleNav}
          >
            <svg x="0" y="0" viewBox="0 0 24 18">
              <rect width="24" height="2" />
              <rect y="8" width="24" height="2" />
              <rect y="16" width="24" height="2" />
            </svg>
          </button>
          <Nav isVisible={this.state.isNavActive}>
            <Link css={navLink} to="/#features" onClick={this._closeNav}>
              Features
            </Link>
            <Link css={navLink} to="/pricing" onClick={this._closeNav}>
              Pricing
            </Link>
            <a
              css={navLink}
              href={`${pathname}#contact`}
              data-tab-index="0"
              onClick={this._closeNav}
            >
              Get in touch
            </a>
            <a
              href="https://brandcloudlines.com/login"
              css={css`
                ${navLink};
                text-decoration: none;
              `}
              onClick={this._closeNav}
            >
              <Button>Log in</Button>
            </a>
            <a
              css={navLink}
              href={`${pathname}#contact`}
              onClick={this._closeNav}
            >
              <Button color={colors.primary} data-tab-index="1" solid float>
                Try the demo
              </Button>
            </a>
          </Nav>
        </NavigationContainer>
      </div>
    );
  }
}

export default Navigation;
