const sizes = {
  small: '576px',
  medium: '768px',
  large: '992px',
  xLarge: '1200px',
  xxLarge: '1550px',
}

export const colors = {
  primary: '#4646bd',
  midPrimary: '#363a61',
  light: '#ffffff',
  mid: '#f5f5f9',
  dark: '#27263a',
  green: '#56d9c4',
  blue: '#1194f6',
  pink: '#ed7bbe',
  orange: '#ffa842',
}

export const layout = {
  bp: Object.keys(sizes).reduce((acc, curr) => {
    return {
      ...acc,
      [curr]: `@media only screen and (min-width: ${sizes[curr]})`,
    }
  }, {}),
}

export const animation = {
  spring: { tension: 180, friction: 20 },
}

export const typography = {}
