import React from 'react';
import { Link } from 'gatsby';
import { css } from 'emotion';
import { darken } from 'polished';
import { ScreenShare, Tools } from '../Icons';
import { colors, layout } from '../../shared/theme';
import { FaTwitter } from 'react-icons/fa';

import Container from '../Container';

const getCurrentYear = () => new Date().getFullYear();

const Divider = ({ direction }) => (
  <div
    css={css`
    ${direction === 'x' ? 'border-bottom' : 'border-left'}: 1px solid ${
      colors.light
    };
    ${direction === 'y' && 'height: 100px'};
    opacity: 0.25;
    display: none;
    ${layout.bp.medium} {
      display: block;
    }
    `}
  />
);

const FooterFlash = ({ icon, heading, body }) => (
  <div
    css={css`
      opacity: 0.5;
      margin-bottom: 1em;
      display: flex;
      align-items: center;
      flex-direction: column;
      text-align: center;
      font-size: 0.8em;
      ${layout.bp.medium} {
        text-align: left;
        flex-direction: row;
        margin-bottom: 0;
      }
    `}
  >
    {icon && (
      <div
        css={css`
          margin-bottom: 0.5rem;
          ${layout.bp.medium} {
            margin-bottom: 0;
            margin-right: 1rem;
          }
        `}
      >
        {icon}
      </div>
    )}

    <div>
      {heading && <strong>{heading}</strong>}
      {body && (
        <p
          dangerouslySetInnerHTML={{
            __html: body,
          }}
        />
      )}
    </div>
  </div>
);

const footerLink = css`
  text-decoration: none;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  display: flex;
  ${layout.bp.large} {
    margin-left: 1rem;
    margin-right: 0;
  }
`;

const FooterNav = () => (
  <div
    css={css`
      display: flex;
      font-size: 12px;
      opacity: 0.5;
      flex-direction: column-reverse;
      text-align: center;
      align-items: center;
      flex-wrap: wrap;
      justify-content: center;
      ${layout.bp.large} {
        justify-content: space-between;
        flex-direction: row;
      }
    `}
  >
    <div>
      <span>© {getCurrentYear()} Brand Cloudlines Ltd.</span> For the love of
      brands.
    </div>
    <div
      css={css`
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: center;
        margin-bottom: 1em;
        a:hover {
          text-decoration: underline;
          opacity: 0.75;
        }
        ${layout.bp.medium} {
          margin: 0;
        }
      `}
    >
      <Link to="/#features" css={footerLink}>
        Features
      </Link>
      <Link to="/pricing" css={footerLink}>
        Pricing
      </Link>
      <Link to="/legal/acceptable-use-policy" css={footerLink}>
        Legals
      </Link>
      <a href="https://twitter.com/brandcloudlines" css={footerLink}>
        <FaTwitter size={15} />
      </a>
    </div>
  </div>
);

const Footer = () => (
  <div
    css={css`
      background-color: ${darken(0.025, colors.dark)};
      color: ${colors.light};
      padding: 4rem 0 2rem;
      overflow: hidden;
      ${layout.bp.medium} {
        padding: 4rem 0 0;
      }
    `}
  >
    <Container
      css={css`
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding-bottom: 4rem;
        align-items: center;
        ${layout.bp.medium} {
          flex-direction: row;
        }
        > * {
          margin: 0.5rem 0;
          ${layout.bp.medium} {
            margin: 0 1rem;
          }
        }
      `}
    >
      <FooterFlash
        heading="Having trouble with your account?"
        body={`Email us on <a href="mailto:support@brandcloudlines.com">support@brandcloudlines.com</a>`}
        icon={<Tools size={40} style={{ padding: 5 }} />}
      />
      <Divider direction="y" />
      <FooterFlash
        heading="Want to schedule a live demo?"
        body={`Email us on <a href="mailto:info@brandcloudlines.com">info@brandcloudlines.com</a>`}
        icon={<ScreenShare size={40} />}
      />
    </Container>
    <Divider direction="x" />
    <Container>
      <FooterNav />
    </Container>
  </div>
);

export default Footer;
