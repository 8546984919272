import React from 'react';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import Navigation from './Navigation';
import Footer from './Footer';
import { Location } from '@reach/router';

import '../assets/fonts/FaktPro-Blond.ttf';
import '../assets/fonts/FaktPro-Light.ttf';
import '../assets/fonts/FaktPro-Medium.ttf';

import './layout.scss';

const Layout = ({ className, children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet>
          <html lang="en" />
          <title>{data.site.siteMetadata.title}</title>
          <meta charSet="utf-8" />
          <meta httpEquiv="x-ua-compatible" content="ie=edge" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, shrink-to-fit=no"
          />
          <meta
            name="description"
            content="The smartest way to create, manage and share digital branding guidelines."
          />
          <script
            type="text/javascript"
            src="//cdn.iubenda.com/cookie_solution/safemode/iubenda_cs.js"
            async
          />
          <script type="text/javascript">{`
            var _iub = _iub || [];
            _iub.csConfiguration = {
              "consentOnScroll": true,
              "lang": "en",
              "siteId": 1152694,
              "cookiePolicyId": 45184077, 
              "banner": { 
                "applyStyles": false, 
                "html": "<div class='iubenda-cs-banner' style='display: block;'><div class='iubenda-cs-content'><div class='iubenda-cs-rationale'><div class='iubenda-banner-content'><p>We're using cookies to help enhance our website. <br>Continue normally or read our <a href='/legal/privacy-policy'>cookie policy</a>.</p></div></div></div></div>"
              } 
            };
          `}</script>
        </Helmet>
        <Location>
          {locationProps => (
            <Navigation
              {...locationProps}
              siteTitle={data.site.siteMetadata.title}
            />
          )}
        </Location>
        <main className={className}>{children}</main>
        <Footer />
      </>
    )}
  />
);

export default Layout;
