import React from 'react'
import IconBase from 'react-icon-base'

export const ScreenShare = props => (
  <IconBase viewBox="0 0 24 24" {...props}>
    <path fill="none" d="M0 0h24v24H0V0z" />
    <path d="M20 18c1.1 0 1.99-.9 1.99-2L22 6c0-1.11-.9-2-2-2H4c-1.11 0-2 .89-2 2v10c0 1.1.89 2 2 2H0v2h24v-2h-4zM4 16V6h16v10.01L4 16zm9-6.87c-3.89.54-5.44 3.2-6 5.87 1.39-1.87 3.22-2.72 6-2.72v2.19l4-3.74L13 7v2.13z" />
  </IconBase>
)

export const Tools = props => (
  <IconBase viewBox="0 0 35 35" {...props}>
    <path d="M33.858,4.525 C35.791,7.928 35.308,12.320 32.391,15.218 C29.897,17.697 26.303,18.404 23.171,17.357 L6.546,33.877 C5.047,35.366 2.617,35.366 1.118,33.877 C-0.381,32.387 -0.381,29.972 1.118,28.483 L17.642,12.063 C16.433,8.882 17.109,5.154 19.687,2.594 C22.603,-0.304 27.023,-0.784 30.448,1.136 L23.523,8.016 L26.934,11.405 L33.858,4.525 ZM11.650,13.940 L5.170,7.501 L2.612,7.670 L0.054,2.418 L1.023,1.454 L1.992,0.491 L7.279,3.033 L7.108,5.575 L13.588,12.014 L11.650,13.940 ZM33.628,28.450 C35.130,29.942 35.130,32.361 33.628,33.853 C32.127,35.345 29.692,35.345 28.191,33.853 L19.427,25.144 L24.864,19.742 L33.628,28.450 Z" />
  </IconBase>
)

export const Lightbulb = props => (
  <IconBase viewBox="0 0 24 24" {...props}>
    <path d="M12,2A7,7 0 0,1 19,9C19,11.38 17.81,13.47 16,14.74V17A1,1 0 0,1 15,18H9A1,1 0 0,1 8,17V14.74C6.19,13.47 5,11.38 5,9A7,7 0 0,1 12,2M9,21V20H15V21A1,1 0 0,1 14,22H10A1,1 0 0,1 9,21M12,4A5,5 0 0,0 7,9C7,11.05 8.23,12.81 10,13.58V16H14V13.58C15.77,12.81 17,11.05 17,9A5,5 0 0,0 12,4Z" />
  </IconBase>
)
