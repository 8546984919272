import styled from 'react-emotion';
import { css } from 'emotion';
import { colors } from '../../shared/theme';
import { transparentize, darken } from 'polished';
import { getContrastColor, hexToRgba } from '../../shared/helpers';

const hoverColor = props =>
  css`
    color: ${props.labelColor ||
      (props.color && getContrastColor(props.color)) ||
      colors.light};
  `;

const hoverBackground = props =>
  css`
    background-color: ${props.solid
      ? darken(0.1, props.color || colors.dark)
      : props.color || colors.dark};
  `;

const hoverFloat = props =>
  css`
    transform: translate3d(0, -2px, 0);
    box-shadow: 0 7px 14px ${hexToRgba(props.color || colors.dark, 0.3)};
  `;

const Button = styled('button')`
  background-color: ${props =>
    props.solid ? props.color || colors.dark : colors.light};

  border-radius: 5px;
  height: 54px;
  display: inline-flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-family: inherit;

  border: ${props =>
    props.solid ? 'none' : `1px solid ${props.color || colors.dark}`};

  color: ${props => {
    return transparentize(
      props.disabled ? 0.5 : 0,
      props.labelColor ||
        (props.color && getContrastColor(props.color)) ||
        colors.dark
    );
    // return transparentize(
    //   props.disabled ? 0.5 : 0,
    //   props.labelColor ||
    //   (props.color && getContrastColor(props.color)) ||
    //   props.solid
    //     ? colors.light
    //     : colors.dark
    // );
  }};

  cursor: pointer;
  padding: 0 1.5rem;
  text-decoration: none;
  font-weight: 600;
  font-size: 15px;
  line-height: 54px;

  ${props =>
    props.float &&
    `box-shadow: 0 4px 6px ${hexToRgba(props.color || colors.dark, 0.2)}`};

  transition: all 0.3s;

  &:hover {
    ${props => !props.disabled && hoverColor};
    ${props => !props.disabled && hoverBackground};
    ${props => props.float && hoverFloat};
  }

  &:focus {
    ${props => !props.disabled && hoverColor};
    ${props => !props.disabled && hoverBackground};
    ${props => props.float && hoverFloat};
  }
`;

export default Button;
