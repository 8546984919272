import hexRgb from 'hex-rgb';
import { colors } from './theme';

export const getContrastColor = color => {
  if (!color) return;
  return parseInt(color.replace('#', ''), 16) < 0xffffff / 2
    ? colors.light
    : colors.dark;
};

export const hexToRgba = (hex, alpha) => {
  const rgb = hexRgb(hex, { format: 'array' })
    .slice(0, -1)
    .join(',');
  return `rgba(${rgb}, ${alpha})`;
};

export const isTrue = value => value && value !== 'false';
